.page-section {
  h1 {
    padding: $s-3 $s-4;
    border-bottom: 2px solid $warning;
  }
  .content {
    max-width: 680px;
    padding: $s-4 $s-6;
  }
}

.page-sub-section {
  position: relative;
  background-color: $background-tinted;
  //width: 680px;
  min-width: 450px;

  &.secondary {
    margin-top: $s-4;
    > .sub-section-title {
      border-color: $secondary;
      padding: $s-2 $s-1;
    }
  }
  &.tertiary {
    > .sub-section-title {
      border-color: $secondary;
      color: $primary-dark;
      svg {
        color: $secondary !important;
      }
    }
    background-color: $background-darker;
  }
  .sub-section-title {
    border-top: 1px solid $primary;
    padding: $s-2 $s-3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h2 {
      line-height: 24px;
    }
  }
  .content {
    padding: $s-3 $s-4;
  }
  .section-type {
    position: absolute;
    left: -40px;
  }
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: $s-4;
}

.secondary-section-title {
  border-top: 1px solid $secondary;
  padding: $s-2 $s-3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: $s-4;
  h2 {
    line-height: 24px;
  }
}

.page-sub-section-buttons {
  position: relative;
  background-color: $background-tinted;
  max-width: 680px;
  padding: $s-3 $s-4;
}

.farm-basic-info-section {
  background: $gray;
  padding: $s-3;
}
