.sidebar-header {
  font-weight: bold;
  color: $primary;
  padding: $s-3 $s-4;
  border-bottom: 1px solid;
  margin: 0 $s-4;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;

  &.active {
    background: $info-opacity;
    font-weight: bold;
  }
  &.clickable:hover {
    background: $info-opacity;
  }
}

.items-header {
  padding: $s-2 $s-5;
  border-bottom: none;

  &.clickable:hover {
    background: $info-opacity;
  }
}

.sidebar-link {
  color: $primary;
  padding: $s-2 $s-6;
  border-bottom: 1px solid;
  margin: 0 $s-4;
  font-size: 14px;
  line-height: 30px;
  white-space: nowrap;
  cursor: pointer;

  &:hover:not(.disabled) {
    background: $info-opacity;
  }

  &.disabled {
    opacity: 0.3;
    cursor: default;
  }

  &.active {
    background: $info-opacity;
    font-weight: bold;
  }
}

.link {
  color: $link;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: darken($link, 20%);
  }
}
