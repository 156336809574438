.info-box {
  display: flex;
  flex-direction: column;
  gap: $s-2;
  background: $info;
  padding: $s-4;
  .title {
    font-weight: 700;
  }
  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
  &.l {
    .title {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
  }
}

.statement {
  display: flex;
  gap: $s-3;
  align-items: flex-start;
  padding: $s-3;
  background: rgba($info, 0.25);
}
