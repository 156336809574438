.recapitulation-table-container {
  background: rgba(164, 204, 61, 0.25);
  padding: $s-4;

  .recapitulation-table {
    width: 100%;
  }

  th, td {
    border-bottom: 1px dashed #00000026;
    padding-top: $s-3;
    padding-left: $s-1;
    p {
      font-weight: bold;
    }
  }

  td p {
    color: rgba(0, 0, 0, 0.6);
  }

  tfoot {
    border-top: 3px dashed rgba(164, 204, 61);
  }
}
