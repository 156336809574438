.alert {
  padding: $s-2 $s-3;
  white-space: pre-line;
  display: flex;
  gap: $s-3;
  border-radius: 4px;
  p {
    font-size: 11px;
  }
  &.error {
    background: $error;
    color: white;
  }
  &.info {
    background: $info;
  }
  &.success {
    background: $primary;
  }
}
.alert-icon {
  &.error {
    color: $error;
  }
  &.info {
    color: $info;
  }
  &.success {
    color: $primary;
  }
}
