:root {
  --primary: #5ca47a;
  --primary-o25: rgba(92, 164, 122, 0.25);
}

// Colors
$primary: #5ca47a;
$secondary: #a8cf45;
$gray: #eeeeee;
$info: #ffcc29;
$info-opacity: #ffcc2964;
$warning: #ffcc29;
$error: #f44336;
$background-tinted: #fafafa;
$background-darker: #eeeeee;
$link: #055afe;

$primary-dark: #397351;

// Spacers
$s-0: 0;
$s-1: 4px;
$s-2: 8px;
$s-3: 16px;
$s-4: 24px;
$s-5: 32px;
$s-6: 64px;
// $s-7: 96px;

$deep-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
  0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
$table-border: 1px solid rgba(0, 0, 0, 0.05);

/////// BREAKPOINTS ////////
$bp-mobile: "screen and (max-width: 700px)";
$bp-mobile-up: "screen and (min-width: 701px)";
$bp-mt: "screen and (max-width: 1023px)";
// $bp-desktop: "screen and (min-width: 1024px)";
$portrait: "screen and (orientation: portrait)";
// $landscape: 'screen and (orientation: landscape)';