.page {
  min-height: calc(100vh - 288px);
}

.page-content {
  padding: 40px;
}

.sticky {
  position: sticky;
  top: $s-3;
  height: fit-content;
}

.clickable {
  cursor: pointer;
}

.w-max-fit {
  max-width: fit-content !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-auto {
  margin-left: auto;
}