.files-list {
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  .files-header {
    padding: $s-3 $s-2;
    font-weight: bold;
  }
  .list {
    overscroll-behavior: contain;
    overflow: auto;
  }
  .file {
    display: flex;
    justify-content: space-between;
    padding: $s-3 $s-2;
    border-bottom: 1px solid $background-darker;
  }
}

.evidence-status {
  height: 30px;
  .status {
    display: flex;
    gap: $s-2;
    justify-content: center;
    width: 100%;
    color: $error;
    p {
      font-size: 14px;
      font-weight: 700;
    }
    &.signed {
      color: $primary;
    }
  }
}

.dropzone-container {
  border: 1px dashed rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  :hover {
    cursor: pointer;
  }
  .dropzone {
    text-align: center;
    padding: 20px;
    color: rgba(0, 0, 0, 0.4);
    p {
      font-weight: 600;
      letter-spacing: 0.4px;
    }
  }
}

.file-upload-page-container {
  display: flex;
  flex-direction: column;
  gap: $s-3;
  justify-content: space-between;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 20px;
}

.preview {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 10px;
  width: calc(100% - 20px);
}

.file-dropzone {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: 2px dashed #000;
  border-radius: 30px;
  color: #bdbdbd;
  font-size: large;
}

// .qr-container {
//   display: flex;
//   text-align: justify;
//   min-height: 200px;
//   min-width: 270px;
//   width: 100%;
//   @media (max-width: 600px) {
//     flex-direction: column;
//     p {
//       padding: 2rem 0;
//     }
//   }
// }
