.full-width {
  width: 100%;
}

.icon-1 {
  height: 14px;
  width: 14px;
}
.icon-2 {
  height: 24px;
  width: 24px;
}
.icon-3 {
  height: 36px;
  width: 36px;
}

.min-w-500 {
  min-width: 500px;
}
