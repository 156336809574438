.header {
  background: $primary;
  font-size: 13px;
  .top-content {
    // @extend .flex, .justify-right, .align-center, .gap-4, .color-white;
    display: flex;
    justify-content: right;
    align-items: center;
    color: white;
    padding: $s-1 $s-4;
    gap: $s-5;
  }
  .bottom-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    border-radius: 24px 0px 0px 0px;
    padding: $s-3;
  }
  .nav-item {
    color: #000;
    font-size: 18px;
    padding: $s-2;
    user-select: none;
    cursor: pointer;
    &:hover,
    &.active {
      color: $primary;
    }
  }
  .user {
    display: flex;
    align-items: center;
    margin-left: $s-2;
    gap: $s-3;
    cursor: pointer;
  }
}
