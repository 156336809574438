$inputLabelWidth: 260px;

.input-container {
  padding: 0 $s-5 0 $s-1;
  .input-item {
    border-bottom: 1px dashed #00000026;
    position: relative;
    display: flex;
    align-items: center;
    &.edit {
      border-bottom: none;
      margin-bottom: 1px;
    }
  }
  .label-container {
    position: relative;
    min-width: $inputLabelWidth;
    width: $inputLabelWidth;
    padding: $s-3 0;
    color: #00000099;
    .label {
      font-size: 12px;
      font-weight: 700;
    }
    .helper-label {
      position: absolute;
      bottom: 0;
      left: $s-2;
      font-size: 9px;
    }
  }
  .input-helper {
    display: flex;
    justify-content: space-between;
    padding-left: calc($inputLabelWidth + $s-3);
    .helper-text {
      font-size: 9px;
      font-weight: 600;
      line-height: 9px;
      color: #00000099;
    }
  }
  .input-alert {
    position: absolute;
    right: -40px;
  }
}

.iui-input-container {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  margin-top: 4px;
  width: 100%;
  box-sizing: border-box;
  &.error {
    .helper-text {
      color: $error;
    }
    fieldset {
      border-color: $error !important;
      &.focus label {
        color: $error !important;
      }
    }
  }
  .helper-text {
    color: rgba(0, 0, 0, 0.5);
    font-size: 9px;
    margin: 1px 14px 0;
  }
  fieldset {
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    margin: 0;
    padding: 0;
    legend {
      margin-left: 7px;
      height: 0px;
      font-size: 10.5px;
      visibility: hidden;
      display: none;
    }
    label {
      position: absolute;
      top: 12px;
      left: 14px;
      transition: 150ms;
      color: rgba(0, 0, 0, 0.5);
      font-size: 13px;
      pointer-events: none;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      z-index: 1;
    }
    &.shrink,
    &.focus {
      label {
        transform: translate(-6px, -17px) scale(0.75);
        transform-origin: top left;
        padding: 0 5px !important;
      }
      legend {
        display: block;
      }
    }
    &.focus {
      border-color: $primary;
      label {
        color: $primary;
      }
    }
  }
  .with-end-button {
    input {
      padding-right: 38px;
    }
    svg {
      &:not(.disabled) {
        cursor: pointer;
        color: rgba(0, 0, 0, 0.8);
        &:hover {
          color: $primary;
        }
      }
      &.disabled {
        opacity: 0.4;
      }
      padding: 9px;
      height: 18px;
      width: 18px;
      margin-left: -37px;
    }
  }
}

input,
textarea {
  &:focus-visible {
    outline: none;
  }
  &:-webkit-autofill {
    animation-name: auto-fill;
    animation-duration: 500ms;
  }
  border-radius: 4px;
  background-color: white;
  font-size: 13px;
  padding: 8px 12px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  // &:disabled {
  //   background-color: #eee;
  // }
  &::placeholder {
    color: #00000050;
    font-weight: 200 !important;
  }
}
textarea {
  resize: none;
}

// Date
.date-input {
  display: flex;
  align-items: center;
  background-color: white;
  height: 32px;
  font-size: 13px;
  position: relative;
  border-radius: 4px;
  &.disabled {
    background-color: #eee;
    input {
      color: #999;
    }
  }
  .inputs {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 14px;
    input[type="number"] {
      padding: 0;
      height: 32px;
      text-align: right;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &.error {
        color: $error;
        font-weight: bold;
      }
    }
  }
  .button {
    color: #cccccc;
    padding: 0 8px !important;
    margin: 8px 0;
    border-left: 1px solid #ccc;
    &:hover:not(.disabled) {
      color: #999;
      cursor: pointer;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
}
.react-calendar {
  border: none !important;
  user-select: none;
}
.react-calendar__tile--active {
  background: $primary !important;
  &:enabled:hover {
    background: darken($primary, 10%) !important;
  }
}
.react-calendar__tile--now {
  background: lighten($warning, 25%) !important;
}

// Select
.fake-input {
  opacity: 0;
  width: 100%;
  height: 1px;
  position: absolute;
  padding: 0px;
}

.surface-input {
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 100%;
  &::after {
    position: absolute;
    bottom: 8px;
    right: 0.5em;
    font-size: 12px;
    content: attr(dataunit);
  }
  &.a input {
    padding-right: 14px;
  }
  input {
    padding-right: 24px;
    text-align: right;
  }
}

// Hide number input arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.checkbox {
  // display: inline-flex;
  // align-items: center;
  // user-select: none;
  // margin: 0 !important;
  align-items: center;
  input {
    position: absolute;
    opacity: 0;
    width: fit-content;
    cursor: pointer;
    margin: 0;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
  .icon {
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    background: #ffffff;
    border: 1px solid #a6adba;
    border-radius: 4px;
    &.selected {
      border: none;
      background: $primary;
      svg {
        height: 14px;
        width: 14px;
        color: white;
      }
    }
  }
}
