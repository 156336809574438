$colors: (
  "white": white,
  "gray": $gray,
  primary: $primary,
  warning: $warning,
  info: $info,
  error: $error,
);

@each $key, $value in $colors {
  .color-#{$key} {
    color: $value !important;
  }
  .background-#{$key} {
    background-color: $value !important;
  }
}
