.dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fade-in 200ms forwards;
  width: 100%;
  height: 100%;
  &.closing {
    animation: fade-out 200ms forwards;
    display: flex;
  }
  .form-container {
    margin: auto;
    max-width: 600px;
  }
  @media #{$bp-mobile} {
    &:not(.mobile-regular) {
      height: auto;
    }
  }
}

.dialog-container {
  box-sizing: border-box;
  background-color: $background-tinted;
  overflow: auto;
  animation: scale-in 200ms forwards;
  &.error {
    border-color: $error;
    .close-btn {
      color: $error;
      &:hover {
        color: darken($error, 20%);
      }
    }
  }
  .close-btn {
    color: $primary;
    &:hover {
      cursor: pointer;
      color: $primary-dark;
    }
  }
  h1 {
    text-align: center;
  }
  .desc {
    line-height: 26px;
    text-align: center;
    white-space: pre-line;
    margin-bottom: $s-4;
  }
  .desc2 {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: $s-3;
    p,
    li {
      font-size: 14px;
      line-height: 26px;
    }
    text-align: justify;
    white-space: pre-line;
    ul {
      margin: 0;
      padding-left: 20px;
      li {
        font-weight: bold;
      }
    }
  }
  .dialog-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: $s-2;
  }
  @media screen and (min-width: 900px) {
    &.size-l {
      min-width: 800px;
    }
    &.size-m {
      width: 800px;
    }
  }
  @media #{$bp-mobile-up} {
    border-radius: 10px;
    border: 1px solid $secondary;
    padding: 24px 40px;
    width: fit-content;
    min-width: 400px;
    max-width: 90vw;
    max-height: 90vh;
    h1 {
      font-weight: 500;
      margin-bottom: 32px;
    }
    .desc {
      font-size: 18px;
    }
  }
  @media #{$bp-mobile} {
    position: relative;
    padding: 24px;
    min-width: 250px;
    width: 100%;
    h1 {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }

  .dialog-link {
    padding: $s-3;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    margin: 0 $s-4;
    font-size: 14px;
    font-weight: bold;
    line-height: 30px;
    white-space: nowrap;
    cursor: pointer;

    &:hover:not(.disabled) {
      background: $info-opacity;
    }

    &.disabled {
      opacity: 0.3;
      cursor: default;
    }
  }
}
