.footer {
  display: flex;
  .container-primary {
    box-sizing: border-box;
    height: 104px;
    background: $primary;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 40px;
    p {
      font-size: 16px;
      line-height: 1.3;
    }
    h1 {
      font-size: 36px;
      line-height: 1.2;
    }
  }
  .container-secondary {
    height: 76px;
    background: $secondary;
  }
}
